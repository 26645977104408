<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col md="4">
        <collection-card :work_group="work_group"></collection-card>
      </b-col>
      <b-col md="4">
        <collection-show :id="id" @gotAttrs="assignAttrs"></collection-show>
      </b-col>
      <b-col md="4">
        <summary-show :id="id"></summary-show>
      </b-col>
    </b-row>
   <b-row>
     <b-col md="4">
      <h3>Work Group</h3>
      <work-group-index
        :filtering="filtering"
      ></work-group-index>
      <collection-image-index
        :filtering="filtering"
      ></collection-image-index>
     </b-col>
     <b-col md="8">
      <h3>Product Items</h3>
      <product-item-index
        :filtering="filtering"
        :per_page_setting="15"
        :ams_template="ams_item_template"
      ></product-item-index>
     </b-col>
   </b-row>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import CollectionShow from './CollectionShow.vue'
import WorkGroupIndex from './WorkGroupIndex.vue'
import ProductItemIndex from './ProductItemIndex.vue'
import CollectionImageIndex from './CollectionImageIndex.vue'
import CollectionCard from './CollectionCard.vue'
import SummaryShow from './SummaryShow.vue'

export default {
  components: {
    VuexBreadcrumb,
    CollectionShow,
    WorkGroupIndex,
    ProductItemIndex,
    CollectionImageIndex,
    CollectionCard,
    SummaryShow
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        collection_ids: this.id
      },
      work_group: {
        name: 'Collection',
        'collection-id': this.id
      },
      ams_item_template: {
        type: 'product-items',
        description: undefined,
        attributes: {
          'product-listing-id': undefined,
          'collection-id': this.id,
          'purchased-price-cents': 0,
          'estimated-min-price-cents': 0,
          'estimated-max-price-cents': 0,
          'authenticated-min-price-cents': 0,
          'authenticated-max-price-cents': 0,
          'asking-price-cents': 0,
          'sold-price-cents': 0,
          'estimated-quality': 9000,
          'authenticated-quality': 0
        }
      },
      ams_image_template: {
        type: 'collection-images',
        description: undefined,
        attributes: {
          'collection-id': this.id,
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_item_template.description = description + ' ' + this.attrs['name']
      this.ams_image_template.description = description + ' ' + this.attrs['name']
    }
  }
}
</script>
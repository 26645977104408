<template>
  <ams-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :describe_belongs_to="describe_belongs_to"
    :filtering="filtering"
    :refresh="refresh"
    :refreshed="doRefresh"
  />
</template>

<script>
import AmsTable from './AmsTable.vue'

export default {
  components: {
    AmsTable
  },
  props: {
    filtering: {
      Type: Object
    },
    refresh: {
      Type: Boolean
    }
  },
  data() {
    return {
      ams_type: 'work-groups',
      ams_api_path: 'api/admin/v1',
      ams_fields: [
       { 
          key: 'attributes.name',
          label: 'Name',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
      ],
      describe_belongs_to: [
        {
          prepend: '',
          type: 'brands',
          description_attr: 'name',
          append: ': '
        },
        {
          prepend: '',
          type: 'product-models',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: '#',
          type: 'product-listings',
          description_attr: 'edition',
          append: ''
        },
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ''
        }
      ]
    }
  },
  methods: {
    doRefresh() {
      this.emit('refreshed', true)
    }
  }
}
</script>
<template>
  <div>
    <summary-table :summary="summary_data"></summary-table>
    <b-table table-info="info" striped hover :items="size_data"></b-table>
  </div>

</template>

<script>
import {http_v1} from '@/axios'
import SummaryTable from './SummaryTable.vue'

export default {
  components: {
    SummaryTable
  },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      summary_data: [],
      size_data: []
    }
  },
  mounted () {
    http_v1.get('api/admin/v1/summary', {
      params: {
        collection_id: this.id
      }
    }).then(response => {
      if( response.data) {
        this.summary_data = response.data.data.filter(el => Object.keys(el)[0] === 'summary')
        this.size_data = response.data.data.filter(el => Object.keys(el)[0] === 'size')
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>
<template>
  <div>
    <b-table striped hover :items="summary">
      <template #cell(price)="row">
        $ {{ (row.value / 100).toFixed(2) }}
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    props: {
      summary: {
        Type: Array
      }
    }
  }
</script>